import imageCompression from 'browser-image-compression';

document.addEventListener('turbo:load', function () {
  // Attach event listener to the file input
  document.querySelectorAll('.multiple-image-field').forEach(function (input) {
    input.addEventListener('change', async function (event) {
      const files = event.target.files;
      const filesArray = Array.from(files);
      const compressedFiles = [];

      const options = {
        maxSizeMB: 10, // Maximum size in MB
        maxWidthOrHeight: 1024, // Maximum width or height
        useWebWorker: true, // Use web worker for compression
      };

      // Disable the submit button
      const submitButton = document.querySelector('input[type="submit"], button[type="submit"]');
      if (submitButton) {
        submitButton.disabled = true;
      }

      // Compress images
      for (const file of filesArray) {
        try {
          const compressedBlob = await imageCompression(file, options);

          // Create a new File object from the Blob
          const compressedFile = new File([compressedBlob], file.name, { type: file.type });
          compressedFiles.push(compressedFile);
        } catch (error) {
          console.error("Error during compression:", error);
        }
      }

      // Clear the input
      event.target.value = '';

      // Create a new DataTransfer object to hold the compressed files
      const dataTransfer = new DataTransfer();

      // Add compressed files to the DataTransfer object
      compressedFiles.forEach((file) => {
        dataTransfer.items.add(file); // Now this should work
      });

      // Update the input with the compressed files
      event.target.files = dataTransfer.files;

      // Optionally, you can display the compressed images or handle them as needed
      console.log(dataTransfer.files); // Check the compressed files

      // Re-enable the submit button
      if (submitButton) {
        submitButton.disabled = false;
      }
    });
  });
});